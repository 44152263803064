import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { calcRem } from "../../styles/utils";
import { RichTextTitle } from "../../ui-kit/RichTextTitle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

const StyledSlider = styled(Slider)`
	.slick-track {
		display: flex !important;
		align-items: stretch !important;
		margin-bottom: 15px !important;
	}
	.slick-slider {
		display: flex !important;
		align-items: stretch !important;
	}
	.slick-track .slick-slide {
		height: 100% !important;
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.slick-track .slick-slide:first-child {
		margin-right: 0.5rem !important;
	}
	.slick-track .slick-slide:last-child {
		margin-left: 0.5rem !important;
	}
	.slick-prev:before,
	.slick-next:before {
		color: #00ccad !important;
	}
`;

const settings = {
	speed: 1500,
	slidesToScroll: 2,
	slidesToShow: 2,
	arrows: true,
	infinite: true,
	autoplay: true,
	autoplaySpeed: 10000,
	pauseOnHover: false,
	draggable: false,
	swipe: false,
	touchMove: false,
	responsive: [
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1300,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const testimonialHeaderStyle = {
	maxWidth: calcRem(1920),
};

const testimonialStyle = {
	borderRadius: calcRem(20),
	boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
	padding: "2.5rem 2.5rem",
};

const testimonalTitleSectionStyle = {
	backgroundColor: "#f4f4f4",
};

let loadScript = function (src) {
	let tag = document.createElement("script");
	tag.async = false;
	tag.src = src;
	document.getElementsByTagName("body")[0].appendChild(tag);
};

/* const TestimonialSection = ({
	title,
	description,
	trustPilotLogo,
	trustPilotText,
	testimonials,
}) => {
	return (
		<div
			className="mx-auto mt-5 px-4 md:px-8 lg:px-16"
			style={testimonialHeaderStyle}>
			<div className="flex flex-col lg:flex-row items-center lg:py-28 lg:gap-x-14">
				<div className="flex flex-col items-center justify-center gap-6 text-center lg:text-left lg:w-5/12 bg-light-gray px-16 py-32">
					<RichTextTitle richText={title} fontSize={"text-6xl"} />
					<a
						className="flex flex-col xs:flex-row md:flex-col lg:flex-row items-center justify-center rounded-full gap-1 px-4 lg:px-3 xs:gap-2 pt-2 pb-3.5 bg-green"
						href="https://www.trustpilot.com/review/kimbocare.com"
						target="_blank">
						<span className="text-center text-white xxs:text-lg xl:text-xl font-bold pt-1">
							{trustPilotText}
						</span>
						<div
							src="https://cdn.trustindex.io/loader.js?36b3fe94213d824ad776f8f8559"
							className="w-full"></div>
					</a>
				</div>
				<div className="flex-grow mt-7 lg:mt-7 w-full lg:w-6/12">
					<StyledSlider {...settings}>
						{testimonials.map((testimonial) => (
							<Testimonial
								key={testimonial.id}
								comment={testimonial.testimonialComment}
								username={testimonial.testimonialUsername}
								country={testimonial.testimonialCountry}
								country_flag={testimonial.testimonialCountryFlag}
								note={testimonial.testimonialNote}
							/>
						))}
					</StyledSlider>
				</div>
			</div>
		</div>
	);
}; */

const Testimonial = ({
	key,
	comment,
	username,
	country,
	country_flag,
	note,
}) => {
	const totalStars = 5;
	return (
		<div
			id={key}
			className="mt-6 p-6 w-full h-full flex flex-col justify-between rounded-xl shadow-md">
			<div className="h-full flex flex-col justify-center items-center md:justify-start md:items-start">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					className="block w-6 h-6 fill-green mb-2"
					viewBox="0 0 975.036 975.036">
					<path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
				</svg>
				<div className="w-full flex justify-center items-center flex-col">
					<p className="font-poppins text-center leading-relaxed mb-3 text-lg">
						{comment}
					</p>
					<span class="inline-block h-1 w-12 rounded bg-green mb-3"></span>
					<div className="mx-auto text-center font-poppins flex flex-col justify-center gap-2 items-center">
						<div className="flex-grow items-center flex flex-col pl-4">
							<div className="flex flex-row items-center gap-2">
								<span className="font-poppins font-medium text-gray-900">
									{username}
								</span>
								<SanityImage
									{...country_flag}
									loading="lazy"
									alt={country}
									className="w-6 h-6"
								/>
							</div>
							<div className="flex gap-0.5 mt-1">
								{Array.from({ length: totalStars }).map((_, index) => (
									<svg
										key={index}
										className={`w-6 h-6 ${
											index < note ? "fill-yellow-500" : "fill-gray-s"
										}`}
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
									</svg>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export class TestimonialSection extends React.Component {
	componentDidMount() {
		if (window) {
			loadScript("https://cdn.trustindex.io/loader.js");
		}
	}

	render() {
		return (
			<div
				className="mx-auto mt-5 px-4 md:px-8 lg:px-16"
				style={testimonialHeaderStyle}>
				<div className="flex flex-col lg:flex-row items-center lg:py-28 lg:gap-x-14">
					<div
						className="flex flex-col items-center justify-center gap-6 text-center lg:text-left lg:w-5/12 px-16 pt-32 pb-24"
						style={testimonalTitleSectionStyle}>
						<RichTextTitle richText={this.props.title} fontSize={"text-6xl"} />
						<div src={this.props.trustPilotText} className="w-full"></div>
						{/*<a
							className="flex flex-col xs:flex-row md:flex-col lg:flex-row items-center justify-center rounded-full gap-1 px-4 lg:px-3 xs:gap-2 pt-2 pb-3.5 bg-green"
							href="https://www.trustpilot.com/review/kimbocare.com"
							target="_blank">
							<span className="text-center text-white xxs:text-lg xl:text-xl font-bold pt-1">
								{this.props.trustPilotText}
							</span>
							
						</a> */}
					</div>
					<div className="flex-grow mt-7 lg:mt-7 w-full lg:w-6/12">
						<StyledSlider {...settings}>
							{this.props.testimonials.map((testimonial) => (
								<Testimonial
									key={testimonial.id}
									comment={testimonial.testimonialComment}
									username={testimonial.testimonialUsername}
									country={testimonial.testimonialCountry}
									country_flag={testimonial.testimonialCountryFlag}
									note={testimonial.testimonialNote}
								/>
							))}
						</StyledSlider>
					</div>
				</div>
			</div>
		);
	}
}

export default TestimonialSection;
