import classNames from "classnames";
import React, { Fragment, useRef, useState, useContext } from "react";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import { Dialog, Transition } from "@headlessui/react";
import * as medicadProviderSectionsStyles from "./medicadProviderSections.module.css";
import { LanguageContext } from "../../../utils/context";

const CallToActionNetworkSection = ({
	title,
	advantages,
	callToActionButtonText,
}) => {
	let [isOpen, setIsOpen] = useState(false);
	const cancelButtonRef = useRef(null);
	const language = useContext(LanguageContext);
	return (
		<>
			{/* <Helmet>
                <script src="//embed.typeform.com/next/embed.js"></script>
            </Helmet> */}
			<div>
				<div className="w-full py-6 lg:py-16 bg-white">
					<div className="w-full px-4 md:w-10/12 lg:w-8/12 mx-auto">
						<h1
							className="w-full text-center py-4 text-3xl md:text-5xl lg:text-5xl font-black font-poppins leading-10 mb-10 md:mb-16"
							style={callTocationNetworkTitle}>
							{title}
						</h1>
					</div>
					<div className="w-full px-4 md:px-14 xl:px-40 mx-auto grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 sm:gap-x-12 md:gap-x-16 lg:gap-x-10 sm:gap-y-32 md:gap-y-16">
						{advantages.map((advantage) => (
							<RenderAdvantages key={advantage._key} {...advantage} />
						))}
					</div>
					<div className="w-full px-4 flex justify-center pt-14 md:pt-24">
						<button
							className={classNames(
								medicadProviderSectionsStyles.joinUsButton,
								"text-lg sm:text-lg md:text-xl w-fit px-11 text-center tracking-wider h-14 md:h-19",
								"flex justify-center items-center rounded-full font-poppins font-semibold",
								"bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding text-white"
							)}
							onClick={() => setIsOpen(true)}>
							{callToActionButtonText}
						</button>
					</div>
				</div>
			</div>
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-50 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setIsOpen}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-50 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<div className="absolute inline-block bg-white w-full lg:w-2/3 xl:w-4/5 top-16 lg:top-0 xl:top-0 right-0 bottom-0 rounded-t-lg lg:rounded-none md:rounded-none xl:rounded-none text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle  modal-wrapper">
								{language == "en" ? (
									<iframe
										src="https://forms.office.com/Pages/ResponsePage.aspx?id=BZbOx39-3EedLZpbzqWdugC06d3USLBJgXWvcGrTwLpURjNZTzBMOE84Tk9LTlVUQTU2WExNUDBJWC4u&embed=true"
										width="100%"
										height="100%"
										style={{ border: 0, overflow: "auto" }}
										title="English Form - Response Page"></iframe>
								) : (
									<iframe
										src="https://forms.office.com/Pages/ResponsePage.aspx?id=BZbOx39-3EedLZpbzqWdugC06d3USLBJgXWvcGrTwLpUNVRYRklBUTYyTFNDSjhUUFVDNTE5NE9HTC4u&embed=true"
										width="100%"
										height="100%"
										style={{ border: 0, overflow: "auto" }}
										title="French Form - Response Page"></iframe>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

const RenderAdvantages = ({ advantageWording, advantageDescription }) => {
	return (
		<div className="py-8 px-8 mb-8 md:mb-0" style={advantageCardStyle}>
			<h2 className="pb-8" style={advantageTitleStyle}>
				{advantageWording}
			</h2>
			<div>
				<RichTextMessage
					className="font-light lg:text-lg"
					richText={advantageDescription}
					isMedicalPartnerDescription={true}
				/>
			</div>
		</div>
	);
};

const callTocationNetworkTitle = {
	color: "#2F2D2D",
};

const advantageCardStyle = {
	boxShadow: "0px 20px 20px #7682B72E",
	borderRadius: "5px",
};

const advantageTitleStyle = {
	font: "normal normal 900 20px/25px Poppins",
	color: "#2ea196",
};

const advantageDescriptionStyle = {
	font: "normal normal 300 15px/22px Poppins",
	color: " #656565",
};

export default CallToActionNetworkSection;
