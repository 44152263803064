import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { calcRem } from "../../../styles/utils";
import { RichTextTitle } from "../../../ui-kit/RichTextTitle";
import "./hp.css";

const HealthPackagesSection = ({
	title,
	description,
	bgImage,
	healthPackages,
}) => {
	return (
		<div className="mx-auto px-8 md:px-14 pb-8 lg:pb-0 mt-28">
			<div className="px-8 lg:px-32 mb-16 md:mb-24">
				<RichTextTitle
					richText={title}
					className="text-4xl font-poppins font-bold text-center leading-15 md:leading-relaxed text-gray-800 mb-8"
					isFirst={true}
				/>
				<p className="text-lg font-poppins text-center text-gray-600 max-w-2xl mx-auto">
					{description}
				</p>
			</div>

			<div className="w-full flex flex-wrap justify-center items-center gap-7 lg:gap-8">
				{healthPackages.map((hp) => (
					<HealthPackage
						key={hp.id}
						image={hp.packageImage}
						title={hp.packageTitle}
						description={hp.packageDescription}
						className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
					/>
				))}
			</div>
		</div>
	);
};

const HealthPackage = ({ key, image, title, description }) => {
	return (
		<div
			key={key}
			className="bg-white w-full md:w-64 lg:w-79 flex flex-col items-center justify-center shadow-md rounded-lg px-6 py-14 text-center"
			style={packageStyle}>
			<div class="mb-3 flex items-center justify-center">
				<SanityImage
					{...image}
					className="md:w-12 lg:w-14 xl:w-16 xs:w-10 w-11"
					alt={title}
					config={{ quality: 100 }}
					loading="eager"
				/>
			</div>
			<div class="flex flex-col text-center items-center justify-center">
				<RichTextTitle
					richText={title}
					isFirst={true}
					fontSize="text-xl xs:text-lg md:text-xl lg:text-2xl"
					className="flex font-bold text-center items-end font-poppins mb-3"
				/>
			</div>
		</div>
	);
};

const packageStyle = {
	borderRadius: calcRem(20),
	boxShadow: "0px 20px 40px #00000015",
};

export default HealthPackagesSection;
