import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Pagination from "../../../ui-kit/pagination/Pagination";
import RecentPosts, { RecentPostsWithSearch } from "../blog/recentPosts";
import "./videoSection.css";
import moment from "moment";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import { RichTextTitle } from "../../../ui-kit/RichTextTitle";

const VideosSection = ({
	posts,
	pagetitle,
	pageDescription,
	newTag,
	mostRecentText,
	searchArticles,
	articles,
	videos,
}) => {
	const pageSize = 5;
	let videosToShow =
		pagetitle == "Live events"
			? videos.filter((video) => video.isEvent)
			: videos.filter((video) => !video.isEvent);
	videosToShow = sortVideos(videosToShow);
	const [fvideos, setFvideos] = useState(videosToShow.slice(0, pageSize));

	return (
		<>
			<div id="fb-root"></div>
			<Helmet>
				<script
					async
					defer
					src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"></script>
			</Helmet>
			<RichTextTitle
				richText={pagetitle}
				className="text-center xl:text-left
                    w-11/12 lg:w-9/12 flex items-center font-black font-poppins mx-auto pt-20 "
				isFirst={true}
			/>
			<div className="w-11/12 lg:w-9/12 mx-auto lg:grid lg:grid-cols-8 lg:gap-24 pt-8 pb-12">
				<div className="lg:col-span-5">
					<RichTextMessage
						richText={pageDescription}
						className="w-full my-4 xl:my-8 text-lg 
                    xl:mr-8 text-center font-poppins xl:text-left mx-auto"
					/>
				</div>
				<div className="lg:col-span-3 w-full"></div>
			</div>
			<div className="w-11/12 lg:w-9/12 mx-auto lg:grid lg:grid-cols-8 lg:gap-24">
				<div className="lg:col-span-5">
					<DisplayVideos newTag={newTag} videos={videosToShow} />
					<Pagination
						className="fffff"
						pageData={videosToShow}
						pageSize={pageSize}
						setData={setFvideos}
					/>
				</div>
				<div className="lg:col-span-3 w-full">
					<RecentPostsWithSearch
						key="RecentPosts"
						searchArticles={searchArticles}
						mostRecentText={mostRecentText}
						posts={articles}
					/>
				</div>
			</div>
		</>
	);
};

const DisplayVideos = ({ newTag, videos }) => {
	return videos.map((video) =>
		video.videoType == "facebook" ? (
			<FacebookEmbed
				newTag={newTag}
				publishDate={video.videoPublishedDate}
				videoId={video.videoId}
			/>
		) : (
			<YoutubeEmbed
				newTag={newTag}
				publishDate={video.videoPublishedDate}
				videoId={video.videoId}
			/>
		)
	);
};

const YoutubeEmbed = ({ newTag, publishDate, videoId }) => (
	<div className="video-responsive mb-16 relative">
		{isNew(publishDate) ? (
			<div className="newTag bg-red-600 rounded-full px-4 py-2 font-poppins">
				{newTag}
			</div>
		) : (
			<></>
		)}
		<iframe
			id="ytplayer"
			type="text/html"
			width="100%"
			height="480"
			src={`https://www.youtube.com/embed/${videoId}`}
			allowFullScreen
			title={`YouTube video player - ${videoId}`}
			style={{ border: "none" }}
		/>
	</div>
);

const FacebookEmbed = ({ newTag, publishDate, videoId }) => (
	<div className="video-responsive  mb-16 relative">
		{isNew(publishDate) ? (
			<div className="newTag bg-red-600 rounded-full px-4 py-2 font-poppins">
				{newTag}
			</div>
		) : (
			<></>
		)}
		<div>
			<div
				className="fb-video"
				data-href={`https://www.facebook.com/FacebookDevelopers/posts/${videoId}`}
				data-width="1000"
				data-allowfullscreen="true"></div>
		</div>
	</div>
);

function isNew(publishDate) {
	let now = moment(new Date());
	return moment.duration(now.diff(publishDate)).asDays() <= 7;
}

function sortVideos(videos) {
	var changed;
	do {
		changed = false;
		for (var i = 0; i < videos.length - 1; i++) {
			if (
				moment(videos[i].videoPublishedDate).isBefore(
					moment(videos[i + 1].videoPublishedDate)
				)
			) {
				var tmp = videos[i];
				videos[i] = videos[i + 1];
				videos[i + 1] = tmp;
				changed = true;
			}
		}
	} while (changed);

	return videos;
}

const ourVideoTitleStyle = {
	textShadow: "0px 3px 32px #00000029",
};
export default VideosSection;
