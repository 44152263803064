import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const PlayButton = ({ setIsOpen }) => {
	return (
		<a
			className="absolute cursor-pointer z-10"
			onClick={() => setIsOpen(true)}
			aria-label="Open YouTube video">
			<StaticImage
				src="../../../../static/play-button.png"
				alt="YouTube play button"
				placeholder="blurred"
				layout="fixed"
				width={80}
				height={80}
			/>
		</a>
	);
};

export default PlayButton;
