import React from "react";
import classNames from "classnames";
import BlockContent from "@sanity/block-content-to-react";

const richTextTitleSerializers = (className, isFirst) => ({
	types: {
		block: (props) =>
			isFirst ? (
				<h1 className={className}>{props.children}</h1>
			) : (
				<h2 className={className}>{props.children}</h2>
			),
	},
	marks: {
		underline: (props) => (
			<span className="text-dark-green">{props.children}</span>
		),
	},
});

export function RichTextTitle({
	className,
	richText,
	fontSize,
	isFirst = false,
}) {
	return (
		<BlockContent
			blocks={richText}
			serializers={richTextTitleSerializers(
				classNames(
					`font-poppins font-normal text-black ${
						isFirst && fontSize ? fontSize : "text-[44px]"
					} text-left xl:${fontSize || "text-5xl"}`,
					className
				),
				isFirst
			)}
		/>
	);
}

export function RichTextTitleCustom({ className, richText, isFirst = false }) {
	return (
		<BlockContent
			blocks={richText}
			serializers={richTextTitleSerializers(
				classNames(
					"font-poppins font-black text-black text-2xl lg:text-5xl text-left xl:text-10xl",
					className
				),
				isFirst
			)}
		/>
	);
}

export function RichTextTitleMini({ className, richText }) {
	return (
		<BlockContent
			blocks={richText}
			serializers={richTextTitleSerializers(
				classNames("font-poppins text-left", className)
			)}
		/>
	);
}

const richTextColorTitleSerializers = (className) => ({
	types: {
		block: (props) => <h1 className={className}>{props.children}</h1>,
	},
	marks: {
		underline: (props) => (
			<span className="text-kimbo-blue">{props.children}</span>
		),
	},
});

export function RichTextColorTitle({ className, richText }) {
	return (
		<BlockContent
			blocks={richText}
			serializers={richTextColorTitleSerializers(
				classNames("font-poppins font-black", className)
			)}
		/>
	);
}
