import React from "react";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import { RichTextTitleMini } from "../../../ui-kit/RichTextTitle";

const WhyWeCreateKimbocare = ({ title, description }) => {
	return (
		<div className="w-full pt-16 lg:pt-32 xl:pt-40 bg-white">
			<div className="w-5/6 px-4 lg:w-1/2  mx-auto">
				<RichTextTitleMini
					className="mx-auto w-full sm:lg:w-fit md:lg:w-fit lg:w-fit xl:w-fit
                 py-4 text-center mb-8 md:mb-16 lg:mb-16 text-3xl md:text-5xl lg:text-5xl font-black font-poppins leading-10 text-black"
					richText={title}
				/>
				<div style={whyDescriptionContentStyle}>
					<RichTextMessage
						richText={description}
						className="my-4 lg:my-8 font-poppins lg:mr-8 text-center w-full leading-loose flex flex-col gap-y-4"
						style={{ color: "#656565", lineHeight: "300px" }}
					/>
				</div>
			</div>
		</div>
	);
};

const whyDescriptionContentStyle = {
	font: "normal normal normal 17px/25px proxima nova",
	color: "#656565",
};

export default WhyWeCreateKimbocare;
