import React, { useContext } from "react";
import { CorporateHeaderSection } from "./CorporateHeaderSection";
import { BoxesSection } from "./BoxesSection";
import { ImpactsMapSection } from "./ImpactsMapSection";
import { BulletedListSection } from "./BulletedListSection";
import { TheyTrustKimboCareSection } from "./TheyTrustKimbocareSection";
import { QuoteSection } from "./QuoteSection";
import { ButtonsSection } from "./ButtonsSection";
import { StepsSection } from "./StepsSection";
import CallToActionNetworkSection from "./MedicalProviderSections/CallToActionNetworkSection";
import HealthcareProviderSection from "./MedicalProviderSections/HealthcareProviderSection";
import { PrivateHeaderSection } from "./PrivateHeaderSection/PrivateHeaderSection";
import AboutUsheaderSection from "./about-us/AboutUsheaderSection";
import OurTeamSection from "./about-us/OurTeamSection";
import { DownloadTheAppSection } from "./DownloadTheAppSection";
import { TrustpilotSection } from "./TrustPilotSection";
import PostsSection from "./blog/PostsSection";
import HelpSection from "./Help/HelpSection";
import SinglePost from "./blog/SinglePosts";
import VideosSection from "./VideosSection/videosSection";
import JobPositinSection from "./JobPosition/JobPositionSection";
import { LanguageContext } from "../../utils/context";
import WhyWeCreateKimbocare from "./about-us/WhyWeCreateKimbocare";
import HolistichSection from "./about-us/HolistichSection";
import BoxesAdvantages from "./about-us/BoxesAdvantages";
import WhereWeServePatentsSection from "./about-us/whereWeServeSection";
import PostsCategorie from "./blog/PostsCategorie";
import { defaultLocale } from "../../utils/i18n";
import HealthPackagesSection from "./Hpackages/HealthPackagesSection";
import TestimonialSection from "./TestimonialSection";
import PartnerWithUsSection from "./MedicalProviderSections/PartnerWithUsSection";

export function Sections({ slug, categorieId, videos, sections, pageContext }) {
	const { locale, location } = pageContext;
	const language = useContext(LanguageContext);

	const isDefaultLocaleActive = locale === defaultLocale.code;
	let activePathWithoutLocale = location || "";
	if (!isDefaultLocaleActive)
		activePathWithoutLocale = (location || "").replace(locale, "");
	const isPrivatePageActive = activePathWithoutLocale === "/";

	const renderCorporateHeaderSection = ({
		_id,
		title,
		text,
		buttonLabel,
		image,
	}) => (
		<CorporateHeaderSection
			key={_id}
			title={title}
			text={text}
			buttonLabel={buttonLabel}
			image={image}
		/>
	);

	const renderPrivateHeaderSection = ({
		_id,
		title,
		text,
		buttonLabel,
		steps,
	}) => (
		<PrivateHeaderSection
			key={_id}
			title={title}
			text={text}
			buttonLabel={buttonLabel}
			steps={steps}
		/>
	);

	const renderTrustpilotSection = ({
		_id,
		title,
		templateId,
		businessUnitId,
	}) => (
		<TrustpilotSection
			key={_id}
			title={title}
			locale={language}
			templateId={templateId}
			businessUnitId={businessUnitId}
		/>
	);

	const renderBoxesSection = ({ _id, spacing, title, boxes }) => (
		<BoxesSection
			key={_id}
			spacing={spacing}
			title={title}
			boxes={boxes.map(({ _key, text, title: boxTitle, icon }) => ({
				key: _key,
				text,
				title: boxTitle,
				icon,
			}))}
		/>
	);

	const renderImpactsMapSection = ({ _id, map, legendItems }) => (
		<ImpactsMapSection
			key={_id}
			map={map}
			legendItems={legendItems.map(({ _key, color, label }) => ({
				key: _key,
				color: color.hex,
				label,
			}))}
		/>
	);

	const renderBulletedListSection = ({
		_id,
		title,
		items,
		image,
		imagePosition,
	}) => (
		<BulletedListSection
			key={_id}
			title={title}
			items={items}
			image={image}
			imagePosition={imagePosition}
		/>
	);

	const renderTheyTrustKimbCareSection = ({
		_id,
		title,
		organizations,
		trophy,
	}) => (
		<TheyTrustKimboCareSection
			key={_id}
			title={title}
			organizations={organizations}
			trophy={trophy}
		/>
	);

	const renderQuoteSection = ({ _id, quote, author, buttonLabel, image }) => (
		<QuoteSection
			key={_id}
			quote={quote}
			author={author}
			buttonLabel={buttonLabel}
			image={image}
		/>
	);

	const renderButtonsSection = (
		{ _id, primaryButton, secondaryButton },
		isPrivatePageActive
	) => (
		<ButtonsSection
			key={_id}
			primaryButton={primaryButton}
			secondaryButton={secondaryButton}
			isPrivatePageActive={isPrivatePageActive}
		/>
	);

	const renderStepsSection = ({ _id, title, step }) => (
		<StepsSection
			key={_id}
			title={title}
			steps={step.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderCallToActionNetworkSection = ({
		_id,
		cntitle,
		advantages,
		callToActionButtonText,
	}) => (
		<CallToActionNetworkSection
			key={_id}
			title={cntitle}
			advantages={advantages.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
			callToActionButtonText={callToActionButtonText}
		/>
	);

	const renderPartnerWithUsSection = ({
		_id,
		partnerTitle,
		partnerDescription,
		partnerImage,
	}) => (
		<PartnerWithUsSection
			key={_id}
			title={partnerTitle}
			description={partnerDescription}
			image={partnerImage}
		/>
	);

	const renderHealthCareProviderSection = ({
		_id,
		hptitle,
		healthcareProviders,
		loadMoreButtonText,
	}) => (
		<HealthcareProviderSection
			key={_id}
			title={hptitle}
			healthcareProviders={healthcareProviders.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
			loadMoreButtonText={loadMoreButtonText}
		/>
	);

	const renderHealthPackagesSection = ({
		_id,
		hPackagesTitle,
		hPackagesDescription,
		healthPackages,
	}) => (
		<HealthPackagesSection
			key={_id}
			title={hPackagesTitle}
			description={hPackagesDescription}
			healthPackages={healthPackages.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const SanityAboutUsheaderSection = ({
		_id,
		banner,
		ourMissionTitle,
		ourMissiondescription,
		ourVisionTitle,
		title,
	}) => (
		<AboutUsheaderSection
			key={_id}
			title={title}
			ourMissionTitle={ourMissionTitle}
			ourMissiondescription={ourMissiondescription}
			ourVisionTitle={ourVisionTitle}
			banner={banner}
		/>
	);

	const renderWhyWeCreateKimbocareSection = ({ _id, title, description }) => (
		<WhyWeCreateKimbocare key={_id} title={title} description={description} />
	);

	const renderHolistichSectionSection = ({ _id, title, description }) => (
		<HolistichSection key={_id} title={title} description={description} />
	);

	const renderBosesAdvantagesSection = ({ _id, boxes, title }) => (
		<BoxesAdvantages
			key={_id}
			title={title}
			boxes={boxes.map(({ _key, items, boxtitle, icon }) => ({
				key: _key,
				items,
				boxtitle: boxtitle,
				icon,
			}))}
		/>
	);

	const renderWhereWeServePatientsSection = ({
		_id,
		description,
		referenceBanner,
		title,
	}) => (
		<WhereWeServePatentsSection
			key={_id}
			title={title}
			description={description}
			referenceBanner={referenceBanner}
		/>
	);

	const renderOurTeamSection = ({ _id, oTtitle, teamMembers }) => (
		<OurTeamSection
			key={_id}
			title={oTtitle}
			teamMembers={teamMembers.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderDownloadTheAppSection = ({
		_id,
		title,
		text,
		buttonLabel,
		image,
	}) => (
		<DownloadTheAppSection
			key={_id}
			title={title}
			text={text}
			buttonLabel={buttonLabel}
			image={image}
		/>
	);

	const renderBlogPostsSection = ({
		_id,
		artitle,
		latestArticles,
		blogdescription,
		viewMore,
		searchArticles,
		ReadMoreButton,
		articles,
	}) => (
		<PostsSection
			key={_id}
			latestArticles={latestArticles}
			blogdescription={blogdescription}
			viewMore={viewMore}
			searchArticles={searchArticles}
			ReadMoreButton={ReadMoreButton}
			artitle={artitle}
			articles={articles.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderPostSection = (
		slug,
		{ _id, latestArticles, searchArticles, articles, mostRecentText }
	) => (
		<SinglePost
			key={_id}
			slug={slug}
			latestArticles={latestArticles}
			searchArticles={searchArticles}
			mostRecentText={mostRecentText}
			articles={articles.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderCategoriePostsSection = (
		categorieId,
		{ _id, latestArticles, blogdescription, whatIsNewAtKimbocareText, articles }
	) => (
		<PostsCategorie
			key={_id}
			categorieId={categorieId}
			latestArticles={latestArticles}
			blogdescription={blogdescription}
			whatIsNewAtKimbocareText={whatIsNewAtKimbocareText}
			articles={articles.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderVideosSection = (
		posts,
		{ _id, pagetitle, pageDescription, newTag, sectiontitle, videos }
	) => (
		<VideosSection
			key={_id}
			newTag={newTag}
			posts={posts}
			mostRecentText={posts.mostRecentText}
			searchArticles={posts.searchArticles}
			pagetitle={pagetitle}
			pageDescription={pageDescription}
			sectiontitle={sectiontitle}
			articles={posts.articles.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
			videos={videos.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderHelpSection = ({
		_id,
		hTtitle,
		description,
		questionsAnswers,
	}) => (
		<HelpSection
			key={_id}
			title={hTtitle}
			description={description}
			questionsAnswers={questionsAnswers.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderjobPositionSection = ({ _id, jtitle, jobImageBanner, jobs }) => (
		<JobPositinSection
			key={_id}
			title={jtitle}
			jobImageBanner={jobImageBanner}
			jobs={jobs.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	const renderTestimonialSection = ({
		_id,
		testimonialTitle,
		testimonialDescription,
		trustPilotLogo,
		trustPilotText,
		testimonials,
	}) => (
		<TestimonialSection
			key={_id}
			title={testimonialTitle}
			description={testimonialDescription}
			trustPilotLogo={trustPilotLogo}
			trustPilotText={trustPilotText}
			testimonials={testimonials.map(({ _key, ...props }) => ({
				key: _key,
				...props,
			}))}
		/>
	);

	return (
		<>
			{sections.filter(Boolean).map(({ __typename, ...section }, index) => {
				switch (__typename) {
					case "SanityCorporateHeader":
						return renderCorporateHeaderSection(section);
					case "SanityPrivateHeader":
						return renderPrivateHeaderSection(section);
					case "SanityTrustpilotSection":
						return renderTrustpilotSection(section);
					case "SanityBoxesSection":
						return renderBoxesSection(section);
					case "SanityImpactMapSection":
						return renderImpactsMapSection(section);
					case "SanityBulletedListSection":
						return renderBulletedListSection(section);
					case "SanityTheyTrustKimboCareSection":
						return renderTheyTrustKimbCareSection(section);
					case "SanityQuoteSection":
						return renderQuoteSection(section);
					case "SanityButtonsSection":
						return renderButtonsSection(section, isPrivatePageActive);
					case "SanityStepsSection":
						return renderStepsSection(section);
					case "SanityCallToActionNetworkSection":
						return renderCallToActionNetworkSection(section);

					case "SanityPartnerWithUsSection":
						return renderPartnerWithUsSection(section);
					case "SanityHealthcareProvidersSection":
						return null;
					//return renderHealthCareProviderSection(section);
					case "SanityHealthPackagesSection":
						return renderHealthPackagesSection(section);
					case "SanityOurTeamSection":
						return null;
					// return renderOurTeamSection(section);
					case "SanityWhyCreatedKimbocareSection":
						return renderWhyWeCreateKimbocareSection(section);
					case "SanityAboutUsheaderSection":
						return SanityAboutUsheaderSection(section);
					case "SanityHolisticApproachSection":
						return renderHolistichSectionSection(section);
					case "SanityWhereWeservePatientsSection":
						return renderWhereWeServePatientsSection(section);
					case "SanityBoxesAvantagesSection":
						return renderBosesAdvantagesSection(section);
					case "SanityDownloadTheAppSection":
						return renderDownloadTheAppSection(section);
					case "SanityPost":
						if (slug) return renderPostSection(slug, section);
						else if (categorieId)
							return renderCategoriePostsSection(categorieId, section);
						else if (videos) return null;
						else return renderBlogPostsSection(section);
					case "SanityHelp":
						return renderHelpSection(section);
					case "SanityVideoSection":
						return renderVideosSection(sections[index + 1], section);
					case "SanityJobSection":
						return renderjobPositionSection(section);
					case "SanityTestimonialSection":
						return renderTestimonialSection(section);
					default:
						return null;
				}
			})}
		</>
	);
}
