import { Link } from "gatsby";
import React from "react";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import { RichTextTitle } from "../../../ui-kit/RichTextTitle";

const CategoriesTitleDisplay = ({
	whatNewAtKimbocare,
	blogdescription,
	categorieTitle,
	viewMore,
	categorieID,
	externalCategorieLink,
}) => {
	return (
		<>
			<div className="w-full flex justify-between">
				<RichTextTitle richText={categorieTitle} className=" " />
				<div className="flex items-center justify-center w-fit">
					{whatNewAtKimbocare ? (
						<Link
							key={categorieID}
							to={`/blog/categories/${whatNewAtKimbocare}`}
							className="flex justify-center items-center uppercase"></Link>
					) : (
						<a
							href={`${externalCategorieLink}`}
							className="flex justify-center items-center uppercase">
							<span className="text-kimbo-blue font-poppins  ">
								{viewMore} &#8594;
							</span>
						</a>
					)}
				</div>
			</div>
			{whatNewAtKimbocare ? (
				<RichTextMessage
					className="text-lg font-poppins pt-12"
					richText={blogdescription}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default CategoriesTitleDisplay;
