import { Link } from "gatsby";
import React, { useContext } from "react";
import "./styles/blog.css";
import classNames from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import { LanguageContext } from "../../../utils/context";

const NewPostItem = ({ post, titleTextSsize }) => {
	const language = useContext(LanguageContext);
	return (
		<div
			className={classNames(
				"category-health-wellness rounded-xl w-full h-full box-border font-poppins text-gray antialiased"
			)}>
			<div className="photo-wrapper w-full h-full scale-with-grid">
				<SanityImage
					className="w-full h-full object-cover"
					{...post.mainImage}
					alt={post.longtitle}
					loading="lazy"
					style={{ objectFit: "cover" }}
				/>
			</div>
			<a href={`${post.externalblogLink}`} className="textshadow">
				<div className="desc-wrapper flex items-end w-full h-full">
					<div className="desc w-full px-8 pb-8">
						<div className="post-meta font-poppins py-2">
							<span className="date">
								<span className="post-date text-white font-poppins textshadow">
									{formatDate(post.publishedAt, language)}
								</span>
							</span>
							<span className="author"></span>
						</div>
						<div className="post-title">
							<h3
								className={classNames(
									"text-white font-poppins font-meduim",
									titleTextSsize
								)}>
								{post.longtitle}
							</h3>
						</div>
					</div>
				</div>
			</a>
		</div>
	);
};

function formatDate(string, language) {
	let options = { year: "numeric", month: "long", day: "numeric" };
	return language === "en"
		? new Date(string).toLocaleDateString("en-US", options)
		: new Date(string).toLocaleDateString("fr-FR", options);
}

export default NewPostItem;
