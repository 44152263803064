import classNames from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import React, { useContext } from "react";
import { calcRem } from "../../styles/utils";
import IosAndroidButton from "../../ui-kit/IosAndroidButton/IosAndroidButton";
import gpdrLogo from "../../assets/images/gdpr_logo.png";
import hipaaLogo from "../../assets/images/hipaa_logo.png";
import sepaLogo from "../../assets/images/sepa.jpg";
import klarnaLogo from "../../assets/images/klarna.png";
import twintLogo from "../../assets/images/twint.png";
import paypalLogo from "../../assets/images/paypal.png";
import visaLogo from "../../assets/images/visa.png";

import * as FooterStyles from "./Footer.module.css";
import { LanguageContext } from "../../utils/context";

const headingStyle = {
	fontSize: calcRem(18),
	lineHeight: calcRem(27),
};

export function Footer({ columns, background, withAppButtonLink }) {
	const language = useContext(LanguageContext);

	return (
		<footer
			className={classNames({
				FooterStyles,
			})}>
			<div className="px-8 lg:px-16 mx-auto lg:py-0 flex flex-col items-center gap-x-4 gap-y-16 md:flex-row md:justify-between mt-8 lg:mt-28">
				<div className="grid md:grid-cols-2 sm:grid-cols-1 gap-10">
					{columns.map(({ type, ...column }) => {
						switch (type) {
							case "SanityContactColumn":
								return <ContactColumn {...column} />;
							case "SanityLinksColumn":
								return <LinksColumn {...column} />;
							default:
								return null;
						}
					})}
				</div>
				<div
					style={{
						borderLeft: "1px solid black",
						height: "500px",
					}}
					className="hidden md:block"></div>
				<div>
					{columns.map(({ type, ...column }) => {
						switch (type) {
							case "SanitySocialNetworksColumn":
								return (
									<SocialNetworksColumn
										withAppButtonLink={withAppButtonLink}
										{...column}
										className="w-1/4"
									/>
								);
							default:
								return null;
						}
					})}
				</div>
			</div>
			<div className="gap-x-4 px-8 pt-8 lg:px-16 lg:pt-16 mx-auto bg-gray-100 rounded-lg shadow-md flex flex-col lg:flex-row  justify-between">
				<div>
					<Title>
						{language === "en" ? "Payment options" : "Options de paiement"}
					</Title>
					<div className="flex items-center justify-center mb-6">
						<label htmlFor="card" className="flex gap-4">
							<img
								src={visaLogo}
								loading="lazy"
								className="h-8 w-auto object-contain"
								alt="Visa"
							/>
							<img
								src={paypalLogo}
								loading="lazy"
								className="h-8 w-auto object-contain"
								alt="PayPal"
							/>
							<img
								src={twintLogo}
								loading="lazy"
								className="h-8 w-auto object-contain"
								alt="Twint"
							/>
							<img
								src={sepaLogo}
								loading="lazy"
								className="h-8 w-auto object-contain"
								alt="Sepa"
							/>
							<img
								src={klarnaLogo}
								loading="lazy"
								className="h-8 w-auto object-contain"
								alt="Marketing Badge"
							/>
						</label>
					</div>
				</div>

				<div>
					<Title>
						{language === "en"
							? "Regulatory compliance with global standards"
							: "Conformité réglementaire avec les normes mondiales"}
					</Title>
					<div className="flex items-center lg:justify-start justify-center mb-6">
						<label htmlFor="card" className="flex gap-4">
							<img
								src={gpdrLogo}
								loading="lazy"
								className="h-12 w-auto object-contain"
								alt="Visa"
							/>
							<img
								src={hipaaLogo}
								loading="lazy"
								className="h-12 w-auto object-contain"
								alt="PayPal"
							/>
						</label>
					</div>
				</div>
			</div>
		</footer>
	);
}

function ContactColumn({ title, address, email }) {
	return (
		<div>
			<Title>{title}</Title>
			<div
				className="whitespace-pre-wrap font-light"
				style={{
					fontSize: calcRem(18),
					lineHeight: calcRem(30),
				}}
				dangerouslySetInnerHTML={{ __html: address }}></div>
			<div
				className="font-poppins font-bold text-gray mt-4"
				style={headingStyle}></div>
		</div>
	);
}

function LinksColumn({ title, links }) {
	return (
		<div>
			<Title>{title}</Title>
			<div
				className="flex flex-col gap-y-4"
				style={{
					fontSize: calcRem(18),
				}}>
				{links.map(({ label, url }) => (
					<a key={label + url} href={url} className="underline">
						{label}
					</a>
				))}
			</div>
		</div>
	);
}

function SocialNetworksColumn({ title, links, withAppButtonLink }) {
	return (
		<div>
			<Title>{title}</Title>
			<div className="flex justify-center items-center lg:justify-start gap-x-6">
				{links.map(({ logo, url }) => (
					<a key={url} href={url}>
						<SanityImage
							{...logo}
							width={logo.asset.metadata.dimensions.width}
							height={logo.asset.metadata.dimensions.height}
							style={{
								width: calcRem(logo.asset.metadata.dimensions.width),
								height: calcRem(logo.asset.metadata.dimensions.height),
							}}
							loading="lazy"
							alt={title}
						/>
					</a>
				))}
			</div>
			{withAppButtonLink ? (
				<div className="mt-12">
					<IosAndroidButton
						androidAppLink="https://play.google.com/store/apps/details?id=com.kimbocare.kimboCareMobile"
						iosAppLink="https://apps.apple.com/fr/app/kimbocare-mobile/id1617607005?l=en"
						isFooter={true}
					/>
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

function Title({ children }) {
	return (
		<h2
			className="font-poppins font-bold text-black mb-8 lg:mb-5 text-center lg:text-left"
			style={headingStyle}>
			{children}
		</h2>
	);
}
