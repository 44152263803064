import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { calcRem } from "../../../styles/utils";
import { RichTextTitle } from "../../../ui-kit/RichTextTitle";

const PartnerWithUsSection = ({ key, title, description, image }) => {
	return (
		<div
			className="grid lg:grid-cols-2 mt-10 md:mt-28 lg:gap-x-10 mb-0 md:mb-10 mx-auto lg:px-16 pb-4 lg:pb-0"
			style={partnerWithUsSectionStyle}>
			<div className="flex lg:hidden items-center">
				<div className="flex-grow items-center justify-center px-4 pb-10">
					<SanityImage
						{...image}
						loading="lazy"
						className="rounded-2xl"
						style={{
							aspectRatio: image.asset.metadata.dimensions.aspectRatio,
						}}
						alt={title}
					/>
				</div>
			</div>
			<div className="flex justify-center">
				<div className="flex flex-col items-center justify-center px-8 lg:px-0 lg:items-start lg:max-w-2xl gap-y-10 md:gap-y-12">
					<RichTextTitle
						className="text-center lg:text-left leading-relaxed"
						richText={title}
						isFirst={true}
						fontSize="text-3xl md:text-4xl lg:text-5xl"
					/>
					<p className="text-center text-xl lg:text-left">{description}</p>
				</div>
			</div>

			<div className="hidden lg:flex items-center">
				<div className="flex-grow lg:pl-10">
					<SanityImage
						{...image}
						loading="lazy"
						className="rounded-2xl"
						style={{
							aspectRatio: image.asset.metadata.dimensions.aspectRatio,
						}}
						alt={title}
					/>
				</div>
			</div>
		</div>
	);
};

const partnerWithUsSectionStyle = {
	maxWidth: calcRem(1920),
};

export default PartnerWithUsSection;
