import classNames from "classnames";
import React from "react";

export function Layout({ children, className }) {
	return (
		<main
			className={classNames(
				"box-border font-poppins text-gray antialiased",
				className
			)}>
			{children}
		</main>
	);
}
