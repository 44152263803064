import classNames from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import React, { useContext, useState, Fragment, useRef } from "react";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import { RichTextTitle } from "../../../ui-kit/RichTextTitle";
import { LanguageContext } from "../../../utils/context";
import PlayButton from "./PlayButton";
import { Dialog, Transition } from "@headlessui/react";

const WhereWeServePatentsSection = ({
	title,
	description,
	referenceBanner,
}) => {
	let [isOpen, setIsOpen] = useState(false);
	const cancelButtonRef = useRef(null);
	const language = useContext(LanguageContext);
	const videoID_fr = "xi8Q0pWn5y4";
	const videoID_en = "k8zuLnqZOqo";
	return (
		<div
			className="container mt-24 mx-auto px-8 sm:w-5/6 md:w-full md:grid md:grid-cols-2 md:gap-x-16 
        md:mt-44 lg:mt-44 xl:mt-443 md:mb-16 lg:mb-16 xl:mb-16">
			<div className={classNames("relative flex justify-center items-center")}>
				<PlayButton setIsOpen={setIsOpen} />

				<SanityImage
					{...referenceBanner}
					style={{
						width: "100%",
						aspectRatio: referenceBanner.asset.metadata.dimensions.aspectRatio,
					}}
					loading="lazy"
					alt={title}
					className="2xl:w-3/4 absolute z-0"
				/>
			</div>
			<div className="flex flex-col justify-center lg:ml-12">
				<RichTextTitle
					richText={title}
					className="mt-7 md:mt-0 md:text-left w-full md:w-5/6"
				/>
				<div className="mt-7 flex justify-start text-xs md:mt-12 lg:text-lg lg:leading-extra-loose">
					<RichTextMessage
						className="w-full md:w-5/6 font-poppins leading-loose flex flex-col gap-y-4"
						richText={description}
					/>
				</div>
			</div>
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-30 inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={setIsOpen}>
					<div
						className="relative pl-4 pt-4 z-30 cursor-pointer"
						onClick={() => setIsOpen(false)}>
						&#10060;
					</div>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-50 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<div
								className="absolute inline-block bg-white w-full h-full lg:w-2/3 
                            xl:w-4/5 top-16 lg:top-0 right-0 bottom-0 
                            rounded-t-lg md:rounded-none text-left overflow-hidden shadow-xl transform transition-all 
                            sm:align-middle modal-wrapper">
								{language == "en" ? (
									<iframe
										id="ytplayer"
										type="text/html"
										width="100%"
										height="100%"
										src={`https://www.youtube.com/embed/${videoID_en}`}
										allowFullScreen
										title={`YouTube video player - EN - ${videoID_en}`}
										style={{ border: "none" }}
									/>
								) : (
									<iframe
										id="ytplayer"
										type="text/html"
										width="100%"
										height="100%"
										src={`https://www.youtube.com/embed/${videoID_fr}`}
										allowFullScreen
										title={`YouTube video player - FR - ${videoID_fr}`}
										style={{ border: "none" }}
									/>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
};

export default WhereWeServePatentsSection;
