import classNames from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";
import { calcRem } from "../../../styles/utils";
import { Check } from "../../../svgs/check";

const BoxesAdvantages = ({ title, boxes }) => {
	return (
		<div className="mt-16 md:mt-28 flex flex-col items-center">
			<div
				className={classNames(
					"scrollbar p-8 lg:pt-16 flex overflow-x-auto gap-x-7 max-w-full lg:gap-x-8"
				)}>
				{boxes.map((box) => (
					<Box {...box} />
				))}
			</div>
		</div>
	);
};

const boxStyle = {
	boxShadow: `${calcRem(0)} ${calcRem(20)} ${calcRem(20)} #7682B72E`,
	borderRadius: calcRem(5),
	padding: "4.125rem",
	width: calcRem(530),
};

function Box({ icon, items, boxtitle }) {
	return (
		<div
			className="flex-shrink-0 flex flex-col items-start bg-white w-96"
			style={boxStyle}>
			<SanityImage
				{...icon}
				width={100}
				style={{
					height: calcRem(100),
					aspectRatio: icon.asset.metadata.dimensions.aspectRatio,
				}}
				loading="lazy"
				className="mb-8"
				alt={boxtitle}
			/>
			{boxtitle && (
				<h3
					className="text-black text-xl md:text-xl  mb-2 ml-2 text-left w-full lg:text-xl font-black font-poppins 
                leading-10"
					style={boxeTitleStyle}>
					{boxtitle}
				</h3>
			)}
			<div>
				{items.map((item) => (
					<div
						key={item}
						className="mt-4 flex justify-start text-lg md:mt-4 lg:text-lg lg:leading-extra-loose">
						<div className="mt-2 ml-2 mr-8">
							<Check className="text-green" />
						</div>
						<div className="font-poppins">{item}</div>
					</div>
				))}
			</div>
		</div>
	);
}

const boxeTitleStyle = {
	font: "normal normal 900 18px/26px Poppins",
	color: "#151D41",
};

export default BoxesAdvantages;
